import cloneDeep from 'lodash/cloneDeep';

import { parseJSON, undefDestroy } from '@webapp/common/lib/utils';
import type { LogicBoolFunc, LogicTransition, LogicTransitionType, LogicType } from '@webapp/common/resources/survey';
import { LogicAction, QuestionType } from '@webapp/common/resources/survey';

import type { Condition, Variant } from './lib';
import {
    createNpsVariants,
    createStarVariants,
    initialCondition,
    initialVariant,
    mapVariantCol,
    variantsInColumns
} from './lib';
import type { QuestionResponse } from './map';

export interface LogicConditionResponse {
    ANSWERS: string;
    TRANSITION_ACTION: string;
    BOOL_FUNC: LogicBoolFunc;
    BREAK_TEXT: string;
    COMPLETE_TEXT: string;
    GOAL: LogicTransition;
    ID: number;
    NEXT_PAGE: number;
    NEXT_QUESTION: number;
    NEXT_QUESTIONS: string;
    NEXT_PAGES: string;
    PARAMS: string; // JSON
    REWRITE: string;
    TO_SURVEY: number;
    TO_WEB: string;
    TRANSITION_TYPE: LogicTransitionType;
    TYPE: LogicType;
    RESPONSES_COUNT: number;
}

export const mapVariant = ({ ANSWER, ID, ORDER }: { ANSWER: string; ID: number; ORDER: number }): Variant => {
    const name = parseJSON<Array<string>>(ANSWER, null);

    return {
        ...cloneDeep(initialVariant),
        id: ID,
        name: name ? name[0] : ANSWER,
        checked: false,
        order: ORDER
    };
};
export const mapCondition = (
    {
        TRANSITION_ACTION,
        BOOL_FUNC,
        BREAK_TEXT,
        COMPLETE_TEXT,
        GOAL,
        ID,
        NEXT_PAGE,
        NEXT_QUESTION,
        NEXT_QUESTIONS,
        NEXT_PAGES,
        PARAMS,
        TO_SURVEY,
        TO_WEB,
        TRANSITION_TYPE,
        TYPE,
        RESPONSES_COUNT
    }: LogicConditionResponse,
    questionData: QuestionResponse
): Condition => {
    const condition: Condition = {
        ...cloneDeep(initialCondition),
        action: TRANSITION_ACTION === 'HIDE' ? LogicAction.HIDE : LogicAction.SHOW,
        expand: true,
        id: ID,
        respondent: TYPE,
        transition: GOAL,
        toQuestion: NEXT_QUESTION,
        toQuestions: NEXT_QUESTIONS ? parseJSON(NEXT_QUESTIONS, []) : [],
        toPages: NEXT_PAGES ? parseJSON(NEXT_PAGES, []) : [],
        toPage: NEXT_PAGE,
        boolFunc: BOOL_FUNC,
        toWebsite: TO_WEB,
        completeText: COMPLETE_TEXT,
        transitionType: TRANSITION_TYPE,
        disqualText: BREAK_TEXT ? String(BREAK_TEXT) : '',
        toSurvey: TO_SURVEY,
        responsesCount: RESPONSES_COUNT,
        variants: []
    };

    const { answers, groups, parameters: { params } = { params: null }, QUESTION_TYPE_ID } = questionData;
    const { amount } = parseJSON<AnyObject>(params, {});
    const questionTypeId = QUESTION_TYPE_ID;
    let variants: Array<Variant> = [];

    if (PARAMS !== null && PARAMS !== '') {
        // TODO core#278
        variants = parseJSON(PARAMS, []);
    }

    if (variantsInColumns.has(questionTypeId)) {
        // TODO merge with reducer
        condition.variants = groups.map((group: any) => {
            const newVariant = mapVariantCol(group);
            newVariant.checked = !!variants.find((v) => v.id === newVariant.id);
            return newVariant;
        });
        return condition;
    }

    switch (questionTypeId) {
        case QuestionType.NPS:
            condition.variants = createNpsVariants(variants);
            break;
        case QuestionType.STAR:
            condition.variants = createStarVariants(Number(amount), variants);
            break;
        case QuestionType.MATRIX_SINGLE_ANSWER:
        case QuestionType.MATRIX_FEW_ANSWERS:
        case QuestionType.MATRIX_RATING:
            const nextVariants: Array<Variant> = [];
            const modCols =
                questionTypeId === QuestionType.MATRIX_RATING
                    ? new Array(amount).fill(null).map((_, idx) => ({ ID: idx + 1 }))
                    : groups;
            modCols.forEach((col, colIdx) => {
                answers.forEach((row, rowIdx) => {
                    const foundedVariant = variants.find(({ id }) => {
                        return id === `${questionData.ID}-${col.ID}-${row.ID}`;
                    });
                    nextVariants.push({
                        ...cloneDeep(initialVariant),
                        id: `${questionData.ID}-${col.ID}-${row.ID}`,
                        name: '',
                        checked: Boolean(foundedVariant?.checked),
                        order: colIdx * answers.length + rowIdx
                    });
                });
            });
            condition.variants = nextVariants;
            break;
        default:
            condition.variants = answers.map((answer: any) => {
                const newVariant = mapVariant(answer);
                newVariant.checked = !!variants.find(({ id }) => id === newVariant.id);
                return newVariant;
            });
    }

    return condition;
};
export const reverseMapCondition = (condition: Partial<Condition>): any =>
    undefDestroy({
        responses_count: condition.responsesCount,
        transition_action: condition.action,
        next_question: condition.toQuestion,
        next_page: condition.toPage,
        type: condition.respondent,
        goal: condition.transition,
        bool_func: condition.boolFunc,
        complete_text: condition.completeText,
        transition_type: condition.transitionType,
        to_web: condition.toWebsite,
        break_text: condition.disqualText,
        to_survey: condition.toSurvey,
        next_questions: condition.toQuestions ? JSON.stringify(condition.toQuestions || []) : undefined,
        next_pages: condition.toPages ? JSON.stringify(condition.toPages || []) : undefined
    });

import { useMemo } from 'react';

import { API_URL } from '@webapp/common/conf';
import { forceHeader } from '@webapp/common/lib/const';
import { BackgroundType, calcHeaderHeight } from '@webapp/common/lib/ui';
import type { InfoDesignModel } from '@webapp/common/resources/entities/info';

export const useSurveyStyle = (
    { brand: { background, backgroundGradient, backgroundImage, headerSize }, fonts: { fontFamily } }: InfoDesignModel,
    isPreview: boolean
): CSSProperties => {
    const pos = isPreview && !forceHeader ? `center calc(50% - ${calcHeaderHeight(headerSize)}px)` : 'center';
    //const { itemsGap /*, questionGap*/ } = useTextStyle();
    const withGradient = background === BackgroundType.GRADIENT;
    const img = useMemo(
        () =>
            backgroundImage
                ? {
                      backgroundImage: withGradient
                          ? `${backgroundGradient}, url(${API_URL + backgroundImage})`
                          : `url(${API_URL + backgroundImage})`,
                      backgroundPosition: pos + (withGradient ? '' : ''),
                      backgroundRepeat: 'no-repeat' + (withGradient ? ', no-repeat' : ''),
                      backgroundSize: withGradient ? '100%, cover' : 'cover'
                  }
                : withGradient
                ? {
                      backgroundImage: `${backgroundGradient}`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '100'
                  }
                : {},
        [backgroundGradient, backgroundImage, pos, withGradient]
    );

    return useMemo(
        () => ({
            fontFamily,
            // rowGap: questionGap,
            backgroundAttachment: 'fixed',
            ...img
        }),
        [fontFamily, img]
    );
};

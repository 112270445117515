import cloneDeep from 'lodash/cloneDeep';
import pickBy from 'lodash/pickBy';

import { placeholder } from '@webapp/common/lib/ui';
import { updateItemInArray } from '@webapp/common/lib/utils';
import {
    LogicAction,
    LogicBoolFunc,
    LogicTransition,
    LogicTransitionType,
    LogicType,
    QuestionType
} from '@webapp/common/resources/survey';

import type { Page } from '../common';

import type { LogicPageQuestion } from './question';

export interface Condition {
    fetch: boolean;
    expand: boolean;
    id: number;
    respondent: LogicType;
    action: LogicAction;
    transition: LogicTransition;
    transitionType: LogicTransitionType;
    boolFunc: LogicBoolFunc;
    toQuestion: number;
    toQuestions: Array<number>;
    toPages: Array<number>;
    toSurvey: number;
    toPage: number;
    toWebsite: string;
    completeText: string;
    disqualText: string;
    variants: Array<Variant>;
    responsesCount: number;
}

export type ConditionParamName = keyof Omit<Condition, 'fetch' | 'expand' | 'id'>;

// TODO use in editor only. store only checked ids
export interface Variant {
    id: number | string;
    name: string;
    checked: boolean;
    order: number;
}

export const initialVariant: Readonly<Variant> = {
    id: 0,
    name: '',
    checked: false,
    order: 0
};

export const createNpsVariants = (variants: Array<Variant> = []): Array<Variant> =>
    Array(11)
        .fill(0)
        .map((_, i) => ({
            ...cloneDeep(initialVariant),
            id: i,
            name: String(i),
            checked: variants.some(({ id }) => id === i)
        }));

export const initialCondition: Readonly<Condition> = {
    fetch: false,
    expand: false,
    id: 0,
    respondent: LogicType.ALWAYS,
    action: LogicAction.SHOW,
    transition: LogicTransition.QUESTION,
    transitionType: LogicTransitionType.TEXT,
    boolFunc: LogicBoolFunc.OR,
    toQuestion: null,
    toQuestions: [],
    toPages: [],
    toSurvey: null,
    toWebsite: null,
    responsesCount: 0,
    toPage: null,
    completeText: null,
    disqualText: placeholder(
        'Приносим свои извинения, но для опроса требуется иная целевая аудитория. Благодарим вас за готовность принять участие!'
    ),
    variants: []
};

export const createNpsCondition = (id, conditions, params: Partial<Condition>): Condition => ({
    ...cloneDeep(initialCondition),
    ...params,
    variants: createNpsVariants(),
    id,
    expand: true
});

export const createStarVariants = (count: number, variants: Array<Variant> = []): Array<Variant> =>
    Array(count)
        .fill(0)
        .map((_, i) => ({
            ...cloneDeep(initialVariant),
            id: i + 1,
            name: String(i + 1),
            checked: !!variants.find(({ id }) => id === i + 1)
        }));

export const createStarCondition = (id, count, params: Partial<Condition>): Condition => ({
    ...cloneDeep(initialCondition),
    ...params,
    variants: createStarVariants(count),
    id,
    expand: true
});

export const mapVariantCol = ({ ID, NAME, ORDER }: { ID: number; NAME: string; ORDER: number }): Variant => ({
    ...cloneDeep(initialVariant),
    id: ID,
    name: NAME,
    checked: false,
    order: ORDER
});

export const variantsWithBoolFunc: Set<QuestionType> = new Set([
    QuestionType.TEST_FEW_OF_LIST,
    QuestionType.SELECT_FEW_IMAGE,
    QuestionType.FEW_OF_LIST,
    QuestionType.MATRIX_FEW_ANSWERS
]);

export const variantsWithMatrix: Set<QuestionType> = new Set([
    QuestionType.MATRIX_SINGLE_ANSWER,
    QuestionType.MATRIX_FEW_ANSWERS,
    QuestionType.MATRIX_RATING
]);

export const variantsWithValues: Set<QuestionType> = new Set([
    QuestionType.TEST_FEW_OF_LIST,
    QuestionType.TEST_ONE_OF_LIST,
    QuestionType.NPS,
    QuestionType.SMILE,
    QuestionType.STAR,
    QuestionType.SCALE,
    QuestionType.DROPDOWN_LIST,
    QuestionType.SELECT_FEW_IMAGE,
    QuestionType.SELECT_ONE_IMAGE,
    QuestionType.FEW_OF_LIST,
    QuestionType.ONE_OF_LIST,
    QuestionType.MATRIX_SINGLE_ANSWER,
    QuestionType.MATRIX_FEW_ANSWERS,
    QuestionType.MATRIX_RATING
]);

export const variantsInColumns: Set<QuestionType> = new Set([QuestionType.SCALE]);

export const variantsWidthRadioGroup: Set<QuestionType> = new Set([
    QuestionType.TEST_ONE_OF_LIST,
    QuestionType.NPS,
    QuestionType.SMILE,
    QuestionType.STAR,
    QuestionType.SCALE,
    QuestionType.DROPDOWN_LIST,
    QuestionType.SELECT_ONE_IMAGE,
    QuestionType.ONE_OF_LIST
]);

export const createCondition = (
    id,
    conditions,
    { cols, rows, type, id: qId, params: qParams },
    params: Partial<Condition>
): Condition => ({
    ...cloneDeep(initialCondition),
    ...params,
    id,
    boolFunc:
        type === QuestionType.MATRIX_SINGLE_ANSWER || type === QuestionType.MATRIX_RATING
            ? LogicBoolFunc.AND
            : LogicBoolFunc.OR,
    expand: true,
    variants: (() => {
        const data = variantsInColumns.has(type) ? cols : rows;

        if (variantsWithMatrix.has(type)) {
            const variants: Array<Variant> = [];
            let modCols: Array<{ id: number }> = cols;
            if (type === QuestionType.MATRIX_RATING) {
                modCols = new Array(qParams.amount).fill(null).map((_, idx) => ({ id: idx + 1 }));
            }
            modCols.forEach((col, colIdx) => {
                rows.forEach((row, rowIdx) => {
                    variants.push({
                        ...cloneDeep(initialVariant),
                        id: `${qId}-${col.id}-${row.id}`,
                        name: '',
                        checked: false,
                        order: colIdx * rows.length + rowIdx
                    });
                });
            });
            return variants;
        }

        return data.map(({ id, order, value }) => ({
            ...cloneDeep(initialVariant),
            id,
            name: value,
            checked: false,
            order
        }));
    })()
});

export const updateCondition = (
    pages: Array<Page>,
    pageId,
    questionId,
    conditionId,
    callback: (condition: Condition) => void | Condition
): void => {
    updateItemInArray(pages, pageId, (p: Page) => {
        updateItemInArray(p.questions, questionId, (q: LogicPageQuestion) => {
            q.conditions = updateItemInArray(q.conditions, conditionId, (c: Condition) => callback(c) || c);
        });
    });
};

const logicParamWhitelist = new Set(['id', 'checked']);

export const prepareVariantsParams = (variants: Array<Variant>): string => {
    let params: Array<Partial<Variant>> = variants.filter(({ checked }: Variant) => checked === true);
    params = params.map((v) => pickBy(v, (_, k) => logicParamWhitelist.has(k)));
    return JSON.stringify(params);
};

export const getOnlyActiveScaleVariants = (question: LogicPageQuestion, variants: Array<Variant>) => {
    if (question.type !== QuestionType.SCALE) {
        return variants;
    }

    if (question.params.scaleType === 'number') {
        return variants
            .map((variant) => {
                const col: any = question.cols.find((col) => col.id === variant.id);
                if (
                    typeof col.numeric_value === 'number' &&
                    question.params.scaleStartDia <= col.numeric_value &&
                    question.params.scaleEndDia >= col.numeric_value
                ) {
                    return { ...variant, name: col.numeric_value.toString() };
                }
                return null;
            })
            .filter((s) => s !== null);
    }
    return variants;
};
